import React, { useEffect } from "react"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from '@mui/material/styles'
import { withAITracking } from "@microsoft/applicationinsights-react-js" 
import { LicenseInfo } from '@mui/x-license-pro';
import { useIsFetching } from "@tanstack/react-query"

import { CLABackDrop } from '@ais/components';
import { theme } from '@ais/theme'; 

import { graphScopes } from "./utilities/scopes";
import { reactPlugin, appInsights } from "./utilities/applicationInsights.js"
import { AppRoutes } from "./routes/AppRoutes"

import "./index.css";

import "@ais/palette/src/tokens/variable.css"
 
LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY_MUIXPRO);

const RedirectToLogin = () => {
	const { instance, inProgress } = useMsal();

	if(inProgress === InteractionStatus.None) {
		instance.loginRedirect({ scopes: [] });
	}

	return (
		<></>
	)
}

const App = () => {
	const { login, error } = useMsalAuthentication(InteractionType.Silent, { scopes: graphScopes })
	const { accounts } = useMsal()
	const isAuthenticated = useIsAuthenticated()
	const isFetching = useIsFetching();

	useEffect(() => {
        if(error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Popup, { scopes: graphScopes })
			return
        }
		if(isAuthenticated) {
			const account = accounts[0]
			if(!account) {
				return
			}
			appInsights.context.user.id = account.idTokenClaims.oid
		  	appInsights.setAuthenticatedUserContext(account.username)

		}
    }, [ error, isAuthenticated, accounts ]);

	return (
		<>
			<AuthenticatedTemplate>
				<ThemeProvider theme={theme}>
					<>
						<ToastContainer />
						{(isFetching > 0) && <CLABackDrop isOpen={true} />}
						<AppRoutes />
					</>
				</ThemeProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<RedirectToLogin />
			</UnauthenticatedTemplate>
		</>
	);
};

export default withAITracking(reactPlugin, App)
